/* eslint-disable no-unused-vars  */

// Note: All classes prefixed with is- are state refs
export enum ComponentStates {
  LOADING = 'is-loading',
  LOADED = 'is-loaded',
  SHOWN = 'is-shown',
  ACTIVE = 'is-active',
  OPEN = 'is-open',
  CLOSED = 'is-closed',
  STICKY = 'is-sticky',
  VISIBLE = 'is-visible',
  HIDDEN = 'is-hidden',
  ANIMATING = 'is-animating',
  FIRST = 'is-first',
  SECOND = 'is-second',
  TRANSITION = 'enable-transition',
  HIGH_PRIORITY = 'high',
}
