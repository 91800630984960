export function Broadcast(
  el: Element | Window,
  eventType: string,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data: Dictionary<any> = {},
  eventBubbles: boolean = true
) {
  el.dispatchEvent(
    new CustomEvent(eventType, {
      bubbles: eventBubbles,
      detail: { ...data },
    })
  );
}
