import { ComponentStates } from '@/models/componentStates';
import { ComponentEvents } from '@/models/events';
import { componentObserver } from './componentObserver';
import { BooleanState } from '@/models/booleanState';

interface ComponentLoadDeclaration {
  name: string;
  fileName: string;
  path?: string;
}
export class ComponentLoadHelper {
  public name: string = 'undefined';
  public fileName: string = 'undefined';
  public path: string = '.';
  public elements!: HTMLElement[];
  public initialised: boolean = false;

  constructor(declaration: ComponentLoadDeclaration) {

    Object.keys(declaration).map((key) => {
      if (typeof key === 'string') {
        this[key as keyof ComponentLoadDeclaration] =
          declaration[key as keyof ComponentLoadDeclaration] ?? '.';
      }
    });

    this.elements = [
      ...(document.querySelectorAll(
        `[data-component="${this.name}"]`
      ) as NodeListOf<HTMLElement>),
    ];
  }

  public register(): void {
    this.elements.forEach((el) => {
      componentObserver.register(el);
    });
  }

  // Remove any load attributes off a given element in the set.
  public clean(element: HTMLElement): void {
    if (this.elements.indexOf(element) >= 0) {
      element.removeAttribute('data-component-height');
    }
  }

  public loadElements(loadModule: () => Promise<unknown>): void {
    this.elements.forEach((el) => {
      if (el.dataset.priority === ComponentStates.HIGH_PRIORITY) {
        loadModule().then((instance) => {
          this.init(instance, el);
        });
      } else {
        el.addEventListener(ComponentEvents.INITIALISED, () => {
          if (el.dataset.initialised != BooleanState.TRUE) {
            el.dataset.initialised = BooleanState.TRUE;
            loadModule().then((instance) => {
              this.init(instance, el);
            });
          }
        });
      }
    });
  }

  /**
    Cannot find a basic type for the Module.default object.
    I don't want to create one incase it introduces issues,
    so we will leave this until a better solution is discovered.
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public init(instance: any, el: HTMLElement) {
    new instance.default(el);
    this.clean(el);
  }
}
