import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';
import { Broadcast } from '../../utilities/broadcast';
import { ComponentEvents } from '../../models/events/componentEvents';
import { Direction } from '@/models/directionStates';
import { ViewportDetail } from '@/models/events/viewportObserverEvents';

class ComponentObserver {
  private pageElements: HTMLElement[] = [];
  private entryObserver: IntersectionObserver;
  private sort: DebouncedFunc<VoidFunc> = debounce(this.sortElementsOrder, 250);

  constructor() {
    this.entryObserver = new IntersectionObserver(
      this.observerHandler.bind(this),
      { threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0] }
    );
  }

  private observerHandler(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.boundingClientRect.width && entry.boundingClientRect.height) {
        const visiblePercentage = Math.ceil(entry.intersectionRatio * 10) * 10;
        const direction =
          entry.boundingClientRect.y < 0 ||
            entry.boundingClientRect.top <
            this.pixelsFromBottom(entry.boundingClientRect.bottom)
            ? Direction.TOP
            : Direction.BOTTOM;
        const payload: ViewportDetail = {
          direction: direction,
          visiblePercentage: visiblePercentage,
          viewportPercentage: this.viewportPercentageFromTop(entry.boundingClientRect.top),
        };

        if (entry.isIntersecting === true) {
          Broadcast(entry.target, ComponentEvents.INITIALISED);
          Broadcast(entry.target, ComponentEvents.VIEWPORT_ENTER, payload);
        } else {
          Broadcast(entry.target, ComponentEvents.VIEWPORT_LEAVE, payload);
        }
      }
    });
  }

  private pixelsFromBottom(bottom: number): number {
    return window.innerHeight - bottom;
  }

  private viewportPercentageFromTop(top: number): number {
    const percentageOfViewport = Math.floor((top / window.innerHeight) * 100);
    if (percentageOfViewport < 0) return 0;
    if (percentageOfViewport > 100) return 100;
    return percentageOfViewport;
  }

  public register(el: HTMLElement): void {
    this.pageElements.push(el);
    this.sort();
    this.entryObserver.observe(el);
  }

  public refresh(el: HTMLElement): void {
    this.entryObserver.unobserve(el);
    this.entryObserver.observe(el);
  }

  public sortElementsOrder(): void {
    this.pageElements.sort((a, b) => {
      if (a === b) {
        return 0;
      }

      const position = a.compareDocumentPosition(b);

      if (
        position & Node.DOCUMENT_POSITION_FOLLOWING ||
        position & Node.DOCUMENT_POSITION_CONTAINED_BY
      ) {
        return -1;
      } else if (
        position & Node.DOCUMENT_POSITION_PRECEDING ||
        position & Node.DOCUMENT_POSITION_CONTAINS
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}

export const componentObserver = new ComponentObserver();
