import { ComponentLoadHelper } from '../../modules/component/componentLoadHelper';

const component = new ComponentLoadHelper({
  name: 'banner-article',
  fileName: 'bannerArticle',
});
component.register();
const c = () => import(
  /* webpackChunkName: "components/[request]" */
  `${component.path}/${component.fileName}`
);

component.loadElements(c);